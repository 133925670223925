//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  props: ["childLoading", "fatData", "changeTab", "activeItem"],
  components: {
    TimeLineModal: () => import("./timeline-modal"),
    appeal: () => import("./appeal"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  data() {
    return {
      IsRealTimeScreenshot: true, //是否显示截图
      selDateTimeLine: null, //选择的日期查看打卡时间线
      teamValue: null, //选择的团队
      teamOptions: [],
      // 请求参数
      childData: {
        // timeYM: yyyy-MM, // 左侧查询的时间
        // time: yyyy-MM'-01 00:00:00', // 子组件自己处理的
      },
      attendanceLoading: false,
      attenceData: [], // 返回数据
      onlyUnusual: false,

      moblieOlData: {}, // 移动端的时候这个数据才利用
      popData: {}, // 需要传递给弹层的数据
      timeAttence: [],
      timeHeaderToggle: "",
      memberName: "",

      //考勤汇总
      attenceTable: [], //个人考勤和成员考勤 日历对象
      // selectTimeYear: new Date().toLocaleString(),
      listType: 0, //考勤统计  选择的考勤组id
      // selectTimeMonth: new Date().getMonth() + 1,
      // selectTime: new Date(),
      selMem: [], //时间视图  选择成语
      selectMonth: new Date(), //考勤查询时间
      Attend: true, //未考勤
      Quit: true, //未离职
      attenceGroup: [], //考勤列表
      modalLoading: false,
      attenceGroupList: [], //考勤统计 （考勤组，成员） 个人列表
      attenceGroupTable: [], //考勤统计(考勤组，成员) 汇总

      selectType: 1, //考勤类型
      selAttenceMem: [], //考勤统计选择成员

      obj: {
        id: "",
        day: "",
      },

      isShowTeam: true,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    timeHeaderToggle(val, oldv) {
      if (!oldv) {
        return;
      }
      if (!this.teamValue) {
        this.$message({
          message: "请选择团队",
          type: "warning",
        });
        return;
      }
      this.childData.time = val;
      this.childData.reqsTime = val;
      this.attenceData = {};
      if (
        this.childData.menuType === "publicAttendance" ||
        this.childData.menuType === "privateAttendance"
      ) {
        this.getAttendance();
      }
    },
    changeTab() {
      this.activeItem = "审核列表";
      this.onlyUnusual = false;
    },
    attenceData: {
      deep: true,
      immediate: true,
      handler: function (newV, oldV) {},
    },
  },
  filters: {
    dateFormat(day) {
      return day.timeFormat("MM月dd日");
    },
  },
  methods: {
    imgChange,
    /**
     * 申诉
     */
    handlApply(obj) {
      this.popData = obj;
      this.$modal.show("appeal"); // 这个打开弹层的原理仔细看下
    },
    /**
     * 成员考勤团队切换
     */
    memberTeamChange(val) {
      this.IsRealTimeScreenshot = val;
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.IsRealTimeScreenshot = team.IsRealTimeScreenshot; //是否显示实时截图
      }
    },
    /**
     * 选择团队后的搜索
     */
    handleSearchData() {
      if (!this.teamValue) {
        this.$message({
          message: "请选择团队",
          type: "warning",
        });
        return;
      }
      this.childData.time = this.timeHeaderToggle;
      this.childData.reqsTime = this.timeHeaderToggle;
      this.attenceData = {};
      this.getAttendance();
    },

    /**
     * 获取团队
     */
    getTeams() {
      if (this.childData.menuType == "privateAttendance" && !this.teamValue) {
        this.$http
          .get("/Teams/GetAllTeams.ashx", {
            params: { searchText: null, type: 2 },
          })
          .then((resp) => {
            if (resp.res == 0) {
              this.teamOptions = resp.data.filter(
                (m) => m.Functions.indexOf("考勤管理") != -1
              );
              var defaultTeamValue = this.user.DefaultTeamId;
              if (this.teamOptions.length > 0 && defaultTeamValue) {
                var team = this.teamOptions.find(
                  (m) => m.Id == defaultTeamValue
                );
                if (team) {
                  this.teamValue = team.Id;
                }
              }
              if (this.teamValue) {
                this.handleSearchData();
              }
            }
          });
      }
    },

    //个人考勤表
    tableHeaderStyle(row) {
      return "table-header";
    },
    attenceCellStyle() {
      return {
        textAlign: "center",
      };
    },
    attenceHeaderStyle() {
      return {
        background: "#f9f9f9",
        textAlign: "center",
        fontWeight: "normal",
        color: "#333",
      };
    },

    getSelMember(arr) {
      this.selMem = arr;
      this.getTimeAttendance();
    },

    // 点击日历,查看时间线
    handleDateInfo(bool, obj) {
      if (!bool) {
        return;
      }
      this.selDateTimeLine = {
        Date: obj.Date,
        UsId: obj.UserId,
        teamId: this.teamValue,
        userName: obj.UserName,
      };
      this.$modal.show("timeline");
    },
    // 拆解父组件传递的参数
    showData() {
      this.childData = JSON.parse(JSON.stringify(this.fatData)); // 来源props
      if (this.childData.teamValue) {
        this.teamValue = this.childData.teamValue;
      }

      // 00  个人考勤
      // debugger;
      if (this.childData.menuType == "privateAttendance") {
        // 时间‘2019-11-01’ 成员‘undefined’
        let obj1 = {
          time: new Date(this.childData.fatTimeYMD + " 00:00:00"), // 给日历使用
          reqsTime: this.childData.fatTimeYMD, //调取接口传递的时间
        };
        this.childData = Object.assign(this.childData, obj1);
        this.timeHeaderToggle = this.childData.time;

        if (this.teamValue) {
          this.getAttendance();
        }
      }
      // 10   时间视图考勤
      if (
        this.childData.menuType === "publicAttendance" &&
        this.childData.tabsNum === "1"
      ) {
        // 时间‘2019-11-18’ 成员 ‘undefined’
        let timeArr = this.childData.fatTimeYMD.split("-");
        let obj2 = {
          time: new Date(this.childData.fatTimeYMD + " 00:00:00"), // 无日历
          publicTab1TimeHeader:
            timeArr[0] + "年" + timeArr[1] + "月" + timeArr[2] + "日",
        };
        this.childData = Object.assign(this.childData, obj2);
        if (this.teamValue) {
          this.getTimeAttendance();
        }
      }
      // 11   //成员考勤
      if (
        this.childData.menuType === "publicAttendance" &&
        this.childData.tabsNum === "2"
      ) {
        this.memberName = this.childData.memberName;
        if (!this.timeHeaderToggle) {
          this.timeHeaderToggle = new Date();
          let obj3 = {
            time: this.timeHeaderToggle,
            reqsTime: this.timeHeaderToggle,
          };
          this.childData = Object.assign(this.childData, obj3);
        } else {
          let obj3 = {
            time: this.timeHeaderToggle,
            reqsTime: this.timeHeaderToggle,
          };
          this.childData = Object.assign(this.childData, obj3);
        }
        // 如果只点击的头tab，那只做前面的切换，不做请求 这种情况没有UsId
        if (this.childData.fatUsId && this.teamValue) {
          this.getAttendance();
        }
      }
    },

    // 使用父组件传递的时间参数 timeYMD，请求日历接口
    // 请求单个成员 某月30天的考勤
    getAttendance() {
      this.attendanceLoading = true;
      this.$http
        .get("/Attendance/MyAttendance.ashx", {
          params: {
            Date: this.childData.reqsTime, // '2019-11-01'
            UsId: this.childData.fatUsId, // 不穿就是当前账号
            teamId: this.teamValue,
          },
        })
        .then((resp) => {
          if (resp.res === 0) {
            this.attenceData = {};
            this.attendanceLoading = false;
            resp.data.map((item) => {
              // 休息日节假日 无cell详情 且永为暗色
              item.isRest = "休息日节假日".includes(item.Type);

              item.isNormalAm =
                item.StartStatus == 0 || item.StartStatus == null
                  ? true
                  : false;
              item.isNormalPm =
                item.EndStatus == 0 || item.EndStatus == null ? true : false;
              if (item.StartStatus !== 0 || item.EndStatus !== 0) {
                item.hasUnusualState = true;
              }
              if (item.StartStatus == null && item.EndStatus == null) {
                item.hasUnusualState = false;
              }
            });

            this.attenceData = resp.data;
            // 给移动端的ol列表一初值，之后只通过click改变
            this.moblieOlData = resp.data[0];
            this.getPersonGatherData();
            this.$emit("updata");
          }
        });
    },

    //获得个人考勤汇总
    getPersonGatherData() {
      if (
        this.childData.tabsNum == 1 &&
        this.childData.menuType == "publicAttendance"
      ) {
        return;
      }
      this.attendanceLoading = true;
      this.$http
        .post("/Attendance/MyAttendanceSummaryByDate.ashx ", {
          Date: this.childData.reqsTime,
          UsId: this.childData.fatUsId
            ? this.childData.fatUsId
            : this.$store.state.user.id,
          teamId: this.teamValue,
        })
        .then((res) => {
          if (res.res == 0) {
            this.attendanceLoading = false;
            this.attenceTable.splice(0, 1, res.data);
          }
        });
    },
    getuserList() {
      let arr = [];
      this.selAttenceMem.forEach((item) => {
        arr.push(item.UId);
      });
      this.modalLoading = true;
      this.$http
        .post("/MGT/Personnel/Work/UsersList.ashx", {
          SDate: this.selectMonth,
          // EDate:this.selectMonth,
          Ids: arr,
          Attend: this.Attend,
          Quit: this.Quit,
        })
        .then((res) => {
          if (res.res == 0) {
            this.attenceGroupTable = [];
            this.attenceGroupTable.push(res.data.SummaryData);
            this.attenceGroupList = [];
            this.attenceGroupList = res.data.UsersData;
            this.modalLoading = false;
          }
        });
    },
    //获取考勤组数据
    getAttenceGroupList() {
      this.modalLoading = true;
      this.$http
        .post("/MGT/Personnel/Work/QueryUsersByGroup.ashx", {
          SDate: this.selectMonth,
          GroupId: this.listType,
          Attend: this.Attend,
          Quit: this.Quit,
        })
        .then((res) => {
          if (res.res == 0) {
            this.modalLoading = false;
            this.attenceGroupTable = [];
            this.attenceGroupTable.push(res.data.SummaryData);
            this.attenceGroupList = [];
            this.attenceGroupList = res.data.UserData;
          }
        });
    },

    // 时间视图考勤
    getTimeAttendance() {
      this.attendanceLoading = true;
      let arr1 = [];
      this.selMem.forEach((item) => {
        arr1.push(item.UsId);
      });
      this.$http
        .post("/Attendance/QueryUsersAttendanceByDate.ashx#", {
          Date: this.childData.fatTimeYMD, // '2019-11-18 这种01 02 03'
          UList: arr1,
          teamId: this.teamValue,
        })
        .then((resp) => {
          if (resp.res === 0) {
            resp.data.map((item) => {
              // 休息日节假日 无cell详情 且永为暗色
              item.isNormalAm =
                item.StartStatus == 0 || item.StartStatus == null
                  ? true
                  : false;
              item.isNormalPm =
                item.EndStatus == 0 || item.EndStatus ? true : false;
              if (item.StartStatus !== 0 || item.EndStatus !== 0) {
                item.hasUnusualState = true;
              }
            });
            // console.log(resp)
            this.timeAttence = resp.data;
            this.attendanceLoading = false;
            this.$emit("updata");
          }
        });
    },
  },
  mounted() {
    const role = this.$xStorage.getItem("user-role");
    if (role.team) {
      this.teamValue = role.team;
      this.isShowTeam = false;
    } else {
      this.isShowTeam = true;
    }
    this.showData();
    this.getTeams();
    // console.log(this.fatData)
  },
};
